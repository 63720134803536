import { Outlet } from 'react-router-dom';

import styles from './MainLayout.module.scss';
import {Header} from "../../components";

export const MainLayout = () => (
    <>
        <Header />
        <Outlet />
    </>
);
