import styles from './About.module.scss';
import {Title} from "../../../../ui/Title/Title";
import {Badge} from "../../../../ui/Badge/Badge";
import {useCalculateAge} from "../../../../hooks/useCalculateAge";
export const About = () => {
  return (
      <div id={"about"}>
          {/*<Title tag={"h2"}>Обо мне</Title>*/}
          {/*<Badge color={"iceberg"}>ищу работу</Badge>*/}
          {/*<div>*/}
          {/*    Женщина, {useCalculateAge('1986-05-23', new Date())},*/}
          {/*    Разведена,*/}
          {/*    есть дочь ({useCalculateAge('2008-06-15', new Date())})*/}
          {/*</div>*/}
          {/*<div>Москва, метро Павелецкая</div>*/}
          {/*<div>+7 (925) 024-42-75 — предпочитаемый способ связи</div>*/}
          {/*<div>ks-31@mail.ru, derievaks@gmail.com</div>*/}
          {/*<div>https://t.me/makienkoks</div>*/}

          <p className={styles.nomargin}><sub>каждую ночь в моих мечтах</sub></p>
          <p>Every <span className={styles.yellow}>night</span> in my dreams</p>
          <p className={styles.nomargin}><sub>я вижу тебя, я чувствую тебя</sub></p>
          <p>I see you, I <span className={styles.yellow}>feel</span> you</p>
          <p className={styles.nomargin}><sub>вот откуда я знаю, что ты продолжаешь</sub></p>
          <p>That is <span className={styles.yellow}>how</span> I know you go on</p>
          <p className={styles.nomargin}><sub>далеко на расстоянии</sub></p>
          <p>Far <span className={styles.yellow}>across</span> the distance</p>
          <p className={styles.nomargin}><sub>и пространства межжу нами</sub></p>
          <p>and spaces <span className={styles.yellow}>between</span> us</p>
          <p className={styles.nomargin}><sub>ты пришел, чтобы показать, что продолжаешь</sub></p>
          <p>you have <span className={styles.yellow}>come</span> to show you go on</p>
          <p className={styles.nomargin}><sub>рядом, далеко, везде ты есть</sub></p>
          <p></p>
          <p>near, far wherever you <span className={styles.yellow}>are</span></p>
          <p className={styles.nomargin}><sub>я верю, что сердце продолжает</sub></p>
          <p>i believe that the <span className={styles.yellow}>heart</span> does go on</p>
          <p className={styles.nomargin}><sub>еще раз ты открываешь дверь</sub></p>
          <p>once more, you open the <span className={styles.yellow}>door</span></p>
          <p className={styles.nomargin}><sub>и ты здесь, в моем сердце</sub></p>
          <p>and you're here <span className={styles.yellow}>in my</span> heart</p>
          <p className={styles.nomargin}><sub>и мое сердце будет продолжать</sub></p>
          <p>and my heart will go on and on.</p>
          <p></p>
          <p className={styles.nomargin}><sub>любовь может коснуться нас один раз</sub></p>
          <p>love can touch us one time</p>
          <p className={styles.nomargin}><sub>и последний на всю жизнь</sub></p>
          <p>and last for a <span className={styles.yellow}>lifetime</span></p>
          <p className={styles.nomargin}><sub>и никогда не отпускай, пока мы не уйдем</sub></p>
          <p>and never let go till we're gone</p>
          <p className={styles.nomargin}><sub>fdsfds</sub></p>
          <p>love was <span className={styles.yellow}>when</span> I loved you</p>
          <p className={styles.nomargin}><sub>fdsfds</sub></p>
          <p>one true time I hold to</p>
          <p>in my life we'll <span className={styles.yellow}>always</span> go on</p>
          <p></p>
          <p><span className={styles.yellow}>near</span>, far wherever you are</p>
          <p className={styles.nomargin}><sub>я верю, что сердце продолжает</sub></p>
          <p>i believe that the heart <span className={styles.yellow}>does</span> go on</p>
          <p className={styles.nomargin}><sub>еще раз ты открываешь дверь</sub></p>
          <p>once more, you open the door</p>
          <p className={styles.nomargin}><sub>и ты здесь, в моем сердце</sub></p>
          <p>and you're here in my heart</p>
          <p className={styles.nomargin}><sub>и мое сердце будет продолжать</sub></p>
          <p>and <span className={styles.yellow}>my</span> heart <span className={styles.yellow}>will</span> go on
              and on.</p>
          <p>there is some <span className={styles.yellow}>___</span> that will not go away</p>
          <p></p>
          <p>you're <span className={styles.yellow}>here</span> , <span className={styles.yellow}>there's</span> nothing
              I
              <span className={styles.yellow}>fear</span></p>
          <p>and I know that my heart will go on</p>
          <p>we'll stay <span className={styles.yellow}>forever</span> this way</p>
          <p><span className={styles.yellow}>you</span> are safe in my heart</p>
          <p>and my heart will <span className={styles.yellow}>go</span> on and on</p>
      </div>
  );
}
