export function Header() {
  return (
    <div>
      {/*<ul>*/}
      {/*  <li><a href="#about">Обо мне</a></li>*/}
      {/*  <li><a href="#">Опыт работы</a></li>*/}
      {/*  <li><a href="#">Образование</a></li>*/}
      {/*  <li><a href="#">Курсы</a></li>*/}
      {/*  <li><a href="#">Навыки</a></li>*/}
      {/*  <li><a href="#">Портфолио</a></li>*/}
      {/*  <li><a href="#">Обратная связь</a></li>*/}
      {/*  <li><a href="#">Контакты</a></li>*/}
      {/*</ul>*/}
    </div>
  );
}
