import React, {useEffect, useState} from 'react';
import words from './words.json';
import styles from './style.module.css';
import {usePageTitle} from "../../hooks/usePageTitle";

function Dictionary() {
    usePageTitle('Словарик');
    const [num, setNum] = useState(0);
    const [wordsList, setWordsList] = useState(words);
    const [word, setWord] = useState<null | string>(null);
    const [translate, setTranslate] = useState<null | string>(null);
    const [isVisible, setIsVisible] = useState(false);
    const [result, setResult] = useState(0);
    const [showResult, setShowResult] = useState(false);
    const [forgottenWords, setForgottenWords] = useState([]);

    const randomWord = (count: number) => {
        const min = Math.ceil(0);
        const max = Math.floor(count - 1);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {
        setNum(randomWord(wordsList.length))
    }, []);

    useEffect(() => {
        const list = wordsList.filter((item, index) => index !== num)
        const ruOrEn = randomWord(2)
        if (ruOrEn === 0) {
            setWord(wordsList[num].word)
            setTranslate(wordsList[num].translate)
        } else {
            setWord(wordsList[num].translate)
            setTranslate(wordsList[num].word)
        }
        setWordsList(list)
    }, [num]);

    const onClickHandler = () => {
        setIsVisible(false)
        setNum(randomWord(wordsList.length))
    }

    const onClickHandlerForget = () => {
        if (!isVisible) {
            setResult(result + 1)
            const words = {
                "word": word,
                "translate": translate
            }
            // @ts-ignore
            forgottenWords.push(words)
            setForgottenWords(forgottenWords)
            console.log(forgottenWords)
        }
        setIsVisible(true)
    }

    const onClickHandlerShowResult = () => {
        setShowResult(true)
    }

    const onClickHandlerStart = () => {
        setWordsList(words)
        setForgottenWords([])
        setNum(randomWord(wordsList.length))
        setIsVisible(false)
        setShowResult(false)
        setResult(0)
    }

  return (
      <div className="App">
          {showResult && (
              <>
                  <p className={styles.word}>Вы забыли {result} слова</p>
                  <p>
                      <button onClick={onClickHandlerStart} className={styles.button}>
                          Давай опять!
                      </button>
                  </p>
                  <p>
                      <button className={styles.button}>
                          Посмотреть какие слова забыла
                      </button>
                  </p>
              </>

          )}
          {!showResult && (
              <>
                  <p className={styles.word}>{word}</p>
                  <p>
                      <button disabled={wordsList.length === 1} onClick={onClickHandler} className={styles.button}>
                          Следующее
                      </button>
                  </p>

                  <p>
                      <button disabled={isVisible} onClick={onClickHandlerForget} className={styles.button}>
                          Забыла
                      </button>
                  </p>
                  {isVisible && (
                      <p className={styles.translate}>{translate}</p>
                  )}
              </>
          )}
          {!showResult && wordsList.length === 1 && (
              <p>
                  <button onClick={onClickHandlerShowResult} className={styles.button}>
                      Посмотреть результат
                  </button>
              </p>
          )}
      </div>
  );
}

export default Dictionary;
