import { createBrowserRouter } from 'react-router-dom';
import { MainLayout } from './../layouts/MainLayout';
import { HomePage } from './../pages/Home';

import { PROTECTED_ROUTES } from './consts';
import { ProtectedRoute } from './ProtectedRoute';
import Dictionary from "../pages/Dictionary";

export const router = createBrowserRouter([
  {
    element: <MainLayout />,
    children: [
      {
        path: PROTECTED_ROUTES.HOME,
        element: (
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        ),
      },
      {
        path: PROTECTED_ROUTES.DICTIONARY,
        element: (
            <ProtectedRoute>
              <Dictionary />
            </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <div>404</div>,
  },
]);
