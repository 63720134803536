import styles from './Welcome.module.scss';
import {usePageTitle} from "../../../../hooks/usePageTitle";
import {About} from "../About/About";

export function Welcome() {
  usePageTitle('Дериёва Ксения Сергеевна - FRONTEND разработчик');

  return (
    <div className={styles.block}>
        <div className={styles.content}>
            {/*<h1>Дериёва Ксения Сергеевна</h1>*/}
            {/*<h2>FRONTEND разработчик</h2>*/}
            <About></About>
            {/*<p>Опыт работы</p>*/}
            {/*<p>Образование</p>*/}
            {/*<p>Курсы</p>*/}
            {/*<p>Навыки</p>*/}
            {/*<p>Портфолио</p>*/}
            {/*<p>Обратная связь</p>*/}
            {/*<p>Контакты</p>*/}
        </div>
    </div>
  );
}
